// import Menu from './components/menu'
import LocomotiveScroll from 'locomotive-scroll';

document.addEventListener('DOMContentLoaded', function () {
  this.container = document.querySelector('.j-menu-container')
  this.but = document.querySelector('.j-menu')
  this.closeBut = document.querySelector('.j-menu-close')
  this.container.onclick = (e) => {
    this.but.classList.add('_open')
  }
  this.closeBut.onclick = (e) => {
    this.but.classList.remove('_open')
  }
  const scroll = new LocomotiveScroll({
    el: document.querySelector('[data-scroll-container]'),
    smooth: true
  });

  // document.querySelectorAll('.j-menu')
  // .forEach(block => {
  //   let Menus = new Menu(block)
  //   Menus.init()
  // })
})

